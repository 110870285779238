<template>
	<component :is="contentComponent" />
</template>

<script>
const _m = () => import('@/pages/answer/m.vue') //代码切割
const _pc = () => import('@/pages/answer/pc.vue') //代码切割
import { mapGetters } from 'vuex'

export default {
	data() {
		return {
			contentComponent: null,
		}
	},
	metaInfo() {
		return {
			title: this.title,
			meta: [
				{
					name: 'description',
					content: this.description,
				},
				{
					name: 'title',
					content: this.title,
				},
				{
					property: 'og:title',
					content: this.title,
				},
				{
					property: 'og:description',
					content: this.description,
				},
			],
		}
	},
	computed: {
		...mapGetters(['deviceType']),
		title() {
			return `Your Answer from ${this.domainConfig['IAmURL']}`
		},
		description() {
			return `Here is the information you requested from ${this.domainConfig['IAmURL']}. Our AI-powered platform, driven by Ghat GPT, provides you with well-researched and accurate answers to your questions. Use this information to expand your knowledge and make well-informed decisions.`
		},
	},
	created() {
		console.log(`deviceType - ${this.deviceType}`)
		if (this.deviceType == 'desktop' || this.deviceType == 'pc') {
			this.contentComponent = _pc
			return
		}
		this.contentComponent = _m
	},
}
</script>
